html {
    height: 100%;
    background-color: #cdcdcd;
    /* The html and body elements cannot have any padding or margin. */
}

body {
    min-height: 100%;
    background-color: transparent;
}

h1,
h2 {
    margin: 0;
}

@media (min-width: 768px) and (max-width:991px) {
    #main .container {
        width: auto;
    }
}

.btn-primary {
    background-color: #009fe3 !important;
}

.fa-times {
    color: #d14836;
}

.fa-plus {
    color: rgb(0, 150, 0);
}

input.form-control,
textarea.form-control,
select.form-control,
div.form-control {
    background-color: #f4f4f4;
    /*
    border: none;
    border-radius: 0;
    font-size: 14px;    
    */
}

input.ng-invalid,
select.ng-invalid,
textarea.ng-invalid {
    background-color: rgb(256, 230, 230) !important;
}

.akit-validation-error {
    color: red;
    font-weight: bold;
}

akit-plot {
    display: block;
    max-width: 400px;
}

/* Loading gears when initializing app*/

.index-busy-gears {
    width: 120px;
    height: 120px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -60px;
    margin-top: -60px;
    opacity: 0.7;
}

.index-gear-gray {
    opacity: 0.5;
}

.index-gear-spin {
    position: relative;
    color: rgb(150, 150, 150);
    width: 40px;
    height: 40px;
    position: absolute;
}

.index-gear-spin-default {
    -animation: index-spin 2.5s infinite linear;
    -webkit-animation: index-spin2 2.5s infinite linear;
}

.index-gear-spin-reverse {
    -animation: index-spin 2.5s infinite linear reverse;
    -webkit-animation: index-spin2 2.5s infinite linear reverse;
}

@-webkit-keyframes index-spin2 {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes index-spin {
    from {
        transform: scale(1) rotate(0deg);
    }

    to {
        transform: scale(1) rotate(360deg);
    }
}

.clickable {
    cursor: pointer;
}

/* Fix for safari: buttons where grey */
.btn-blank button {
    background-color: transparent;
}

akit-cms .katex-display {
    display: inline-block;
    margin: 0;
}


/* Temporary fix for general styles in authoring tools */
.btn-primary {
    background-color: #009fe3;
}

.btn-primary:hover {
    background-color: #00587d;
}

.akit-button.primary {
    background-color: #009fe3;
    color: white;
}

.akit-button.primary:hover {
    background-color: #17baff;
}

.akit-button.primary:active {
    background-color: #4ac9ff;
    border-color: #009fe3;
}

akit-dropdown a.dropdown-item {
    color: #009fe3;
}

.optional-button {
    color: #009fe3;
}

.btn.active {
    background-color: #009fe3;
    border-color: #009fe3;
}

.active .btn,
.btn.active {
    background-color: #009fe3;
}

.btn-group.active,
.active.btn-group {
    border-bottom: 2px solid #009fe3;
}

.akit-interaction-placeholder {
    background-color: #009fe3;
}

akit-interaction-editor .step-buttons-hr {
    border-color: #009fe3;
}

akit-interaction-editor .active .btn,
akit-interaction-editor .btn.active {
    color: #009fe3;
}

akit-interaction-editor .strategy-points.valid {
    background-color: #009fe3;
}

.strategy-step-points {
    background-color: #009fe3;
}

akit-question-editor .interaction-tabs-line {
    border-bottom: 2px solid #009fe3;
}

akit-question-editor .interaction-wrapper-background {
    border-bottom: 1px solid #009fe3;
}

.spec-collapsable.open .summary {
    background-color: #009fe3;
}

.spec-collapsable .content-inner {
    border: 2px solid #009fe3;
}

akit-expression-wizard-elm .highlight select {
    background-color: #336699 !important;
}

akit-algebra-spec-editor .span-extended-inactive {
    color: #009fe3;
}

akit-algebra-spec-editor .span-extended-active {
    background-color: #009fe3;
}

akit-algebra-spec-editor .btn.non-empty {
    background-color: #009fe3;
}

akit-algebra-spec-editor .btn.active {
    background-color: #009fe3;
    border-color: #009fe3;
}

akit-algebra-spec-editor .placeholder-elm {
    color: #009fe3;
}

.akit-toastr-link {
    font-weight: bold;
    text-decoration: underline;
}

.tooltip-container {
    text-align: center;
    z-index: 100;
    position: absolute;
    padding: 6px 12px;
    border-radius: 4px;


    line-height: initial;
    color: white;
    width: auto;
    background: #111111ee;
    box-sizing: border-box;
    opacity: 0;

    animation: tooltip-slide 0.18s ease-in 0.3s;
    animation-fill-mode: forwards;
    pointer-events: none;
}

@keyframes tooltip-slide {
    0% {
        opacity: 0;

    }

    100% {
        opacity: 1;

    }
}

.display-flex-center {
    display: flex;
    align-items: center;
    height: 100%;
}